.footer {
  color: $white;
}

.footer__title {
  color: inherit;
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    margin-bottom: 2rem;
  }
}

.footer__text {
  font-size: $small-text-font-size;
  line-height: 1.5;
}

// Contact Form
// ============

.footer__contact {
  padding: 2.5rem 0 2rem;
  background-color: $blue-lagoon;

  .button {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-bottom: 0;
  }

  .cell:first-child {
    margin-bottom: 2rem;

    @include breakpoint(medium up) {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium up) {
    padding: 4rem 0 3rem;
  }
}

.footer__contact-toggle {
  width: 100%;

  .expanded & {
    display: none;
  }
}

.footer__contact-content {
  display: none;

  input {
    margin-bottom: 1rem;
    margin-right: 0;

    @include breakpoint(large up) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  .button {
    width: 100%;

    @include breakpoint(medium up) {
      width: auto;
    }
  }

  .expanded & {
    display: flex;
    flex-flow: wrap row;

    @include breakpoint(large up) {
      flex-wrap: nowrap;
    }
  }

  .success & {
    display: none;
  }
}

.footer__contact-message {
  margin-top: 1rem;

  font-size: $label-font-size;
  font-weight: bold;
}

.footer__contact-message--success {
  display: none;

  .success & {
    display: block;
  }
}
.footer__contact-message--validation {
  display: none;

  .validation-error & {
    display: block;
  }
}
.footer__contact-message--error {
  display: none;

  .error & {
    display: block;
  }
}

// Newsletter Form
// ===============

.newsletter {
  @include breakpoint(medium up) {
    display: flex;
    flex-direction: column;
  }
}

.newsletter__form {
  .button {
    width: 100%;

    @include breakpoint(medium up) {
      align-self: flex-start;
    }
  }

  @include breakpoint(medium up) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex-grow: 1;
  }
}

// Footer Navigation
// =================

.footer__meta {
  padding: 3rem 0;
  background-color: $alizarin-crimson;

  text-align: center;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  @include breakpoint(medium up) {
    padding: 4rem 0;
    text-align: left;
  }
}

.footer__navigation-wrapper {
  @include breakpoint(medium up) {
    display: flex;
    justify-content: space-between;
  }
}

.footer-navigation {
  margin-top: 2.5rem;

  @include breakpoint(medium up) {
    margin-top: 0;
  }
}

.footer-navigation--meta {
  @include breakpoint(medium up) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }
}

.footer-navigation__list {
  list-style: none;

  li {
    display: block;
    margin: 0 0 1rem 0;
  }
}

.footer-navigation__item {
  font-size: $small-text-font-size;
  font-weight: bold;
  text-transform: uppercase;
}
