$font-body: Arial, sans-serif;
$font-headings: $font-body;

$body-font-size-small: 1rem; // 16px
$body-font-size: 1.125rem; // 20px
$body-line-height: 1.3;

$h1-font-size: rem-calc(54px);
$h2-font-size: rem-calc(26px);
$h3-font-size: $global-font-size;

$label-font-size: rem-calc(14);
$small-text-font-size: rem-calc(16);
$form-font-size: rem-calc(18);

/* Basic heading styles */
h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: bold;
  font-family: $font-headings;
  color: $mine-shaft;

  a {
    color: $mine-shaft;

    &:hover {
      color: lighten($mine-shaft, 20%);
    }

    &:not([href]) {
      color: inherit;
    }
  }
}

/* Playholders of specific heading styles */
%h1-font-size {
  @include resprop(
    "font-size",
    ($h1-font-size * 0.55, $h1-font-size * 0.85, $h1-font-size)
  );
  margin-bottom: 0.42em;
}

%h2-font-size {
  @include resprop("font-size", ($h2-font-size * 0.8, $h2-font-size));
}

%h3-font-size {
  @include resprop("font-size", ($body-font-size-small, $h3-font-size));
}

%inherit-heading-colors {
  h1,
  h2,
  h3,
  h4 {
    color: inherit;
  }
}

h1 {
  @extend %h1-font-size;
}

h2 {
  @extend %h2-font-size;
}

h3 {
  @extend %h3-font-size;
}

p,
form,
table {
  margin-bottom: 1rem;
}

small {
  font-size: 70%;
}

body {
  font-family: $font-body;
  font-size: $body-font-size-small;
  line-height: $body-line-height;
  color: $black;

  @include breakpoint(medium up) {
    font-size: $body-font-size;
  }
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: $black;
}

.richtext {
  p {
    margin-bottom: 1.5rem;
  }

  ul,
  ol {
    padding-left: 1rem;
    margin-bottom: 1.5rem;
  }
}

.small {
  font-size: $body-font-size-small;
}

%label {
  display: block;
  margin-bottom: 1rem;

  color: $cloudy;
  font-size: $label-font-size;
  font-weight: bold;
  text-transform: uppercase;
}

.label {
  @extend %label;
}

%hyphenate {
  word-break: break-word;
  hyphens: auto;
}
