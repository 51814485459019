$column-gutter-small: rem-calc(map-get($grid-column-gutter, small));
$column-gutter-medium: rem-calc(map-get($grid-column-gutter, medium));

$border-width: 0.4rem;
$border-radius: 0.1rem;

$global-transition: 0.2s ease-in-out;
$navigation-transition: 0.3s ease;

$header-color-bar-height: 1rem;
$header-color-bar-height-medium: 2rem;
$header-color-bar-height-large: 2.5rem;
$header-height: 5.5rem + $header-color-bar-height;
$header-height-medium: 8.75rem + $header-color-bar-height-medium;
$header-height-large: 10rem + $header-color-bar-height-large;
$header-height-scrolled: 7.5rem + $header-color-bar-height-medium;

$header-bp-value: 868px;
$header-bp: -zf-bp-to-em($header-bp-value);
$header-bp-only: "only screen and (max-width: #{-zf-bp-to-em($header-bp-value - 1px)})";

%transition-all-ease {
  transition: all 0.2s ease;
}

%divider {
  border-bottom: 4px $ziggurat solid;
}

hr {
  @extend %divider;
  border-top: none;
  border-right: none;
  border-left: none;

  margin: 1.5rem 0;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.expanded-nav {
    @include breakpoint(small only) {
      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba($black, 0.5);
    z-index: 1;

    visibility: hidden;
    opacity: 0;

    transition: $navigation-transition;
    transition-property: opacity, visibility;
    will-change: opacity, visibility;
  }
}

a {
  color: $primary-color;

  &:hover {
    color: lighten($primary-color, 20%);
  }

  &:not([href]) {
    color: inherit;
  }
}

main {
  flex: 1 0 auto;
  padding-top: $header-height;

  .custom-sticky-header & {
    padding-top: 0;
  }

  @include breakpoint($header-bp) {
    padding-top: $header-height-medium;
  }

  @include breakpoint(large up) {
    padding-top: $header-height-large;
  }
}

.arrow-link {
  display: inline-block;
  background: url(./img/icons/arrow-right.svg) no-repeat center / contain;
  width: rem-calc(28);
  height: rem-calc(13);

  will-change: transform;
  transition: 0.35s ease transform;

  &:hover {
    transform: translateX(0.5rem);
  }
}

// Page Sections
// =============

.page-section {
  padding: 2rem 0;

  &:last-child {
    padding-bottom: 2.5rem;
  }

  @include breakpoint(medium up) {
    padding: 3rem 0;

    &:last-child {
      padding-bottom: 5rem;
    }
  }
}

.page-section--header {
  padding-top: 3.25rem;

  @include breakpoint(medium up) {
    padding-top: 5rem;
  }
}

.page-section--topics {
  background-color: $blue-lagoon;
}

.page-section--projects {
  background-color: $alizarin-crimson;
}

.page-section--about-us {
  background-color: $pelorous;
}

.page-section--alternating {
  background-color: $pampas;
}

.page-section--topics,
.page-section--projects {
  @extend %inherit-heading-colors;
  color: $white;
}

// Maps
// ====

.maps {
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.map-wrapper {
  @include responsive-embed(1 by 1);

  @include breakpoint(medium up) {
    @include responsive-embed(16 by 9);
    margin-bottom: 2rem;
  }
}

// Images
// ======

.image {
  margin-bottom: 2rem;
}

.image__file {
  display: block;
}

.image__caption {
  font-size: $small-text-font-size;
  margin-top: 0.5rem;
}

// Anchor offset for sticky header
// ===============================

.anchor {
  display: block;
  position: relative;
  top: -($header-height + 1rem);
  visibility: hidden;

  @include breakpoint($header-bp) {
    top: -($header-height-scrolled + 2rem);
  }
}

a[id]:not([href])::before,
a[name]:not([href])::before {
  content: " ";
  display: block;
  margin-top: -($header-height + 1rem);
  height: $header-height + 1rem;
  visibility: hidden;
  pointer-events: none;

  @include breakpoint($header-bp) {
    margin-top: -($header-height-scrolled + 1rem);
    height: $header-height-scrolled + 1rem;
  }
}
