// Teaser block
// ==============

.teaser-block--boxed {
  background-color: $blue-lagoon;
  color: $white;
  padding: 2rem 2rem 0;

  @include breakpoint(medium up) {
    padding: 3rem 3rem 0rem;
  }
}

.teaser-block__list {
  list-style: none;
}

.teaser-block__title {
  @extend %hyphenate;

  a {
    .teaser-block--boxed & {
      color: $white;
    }
  }
}

.teaser-block__item {
  @extend %divider;
  margin-bottom: 1.25rem;

  @include breakpoint(medium up) {
    margin-bottom: 2rem;
  }

  .teaser-block--boxed & {
    border-bottom: none;
  }
}

.teaser-block__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  height: 100%;
  padding-bottom: 0.75rem;

  p {
    font-size: $small-text-font-size;
  }

  @include breakpoint(medium up) {
    padding-bottom: 1rem;
  }
}

.teaser-block__topic {
  @extend %label;

  .teaser-block--boxed & {
    color: $white;
  }
}
