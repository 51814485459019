// Selection
// =========

.project-selection {
  padding: 2rem 0rem 0.5rem;

  @include breakpoint(medium up) {
    padding: 4rem 0rem 0.5rem;
  }
}

// Search form
// ===========

.project-search {
  padding: 2rem 0 1rem;
}

.project-list__form {
  margin-bottom: 0;

  label {
    display: block;
    margin-bottom: 0.5rem;

    text-transform: uppercase;
    font-size: $label-font-size;
    font-weight: bold;
  }

  select {
    appearance: none;
    background: transparent url(./img/icons/arrow-select.svg) no-repeat right
      1rem center / 1.4rem;
    border: 3px solid;

    color: $white;
    font-size: $label-font-size;
    font-weight: bold;
    text-transform: uppercase;

    padding-right: 3rem;
    padding-left: 1rem;

    &::-ms-expand {
      display: none;
    }

    @include breakpoint(medium up) {
      font-size: $small-text-font-size;
    }
  }

  option {
    text-transform: none;
  }
}

.project-list__search-wrapper {
  display: flex;
  flex-wrap: nowrap;

  margin-bottom: 2rem;

  input[type="search"] {
    margin: 0;
    min-width: 0;
  }

  button[type="submit"] {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3rem;
    padding: 0;
    margin: 0;

    background-color: $white;

    transition: background-color $global-transition;

    .magnifier path {
      transition: fill $global-transition;
    }

    &:hover,
    &:focus {
      background-color: $mine-shaft;

      .magnifier path {
        fill: $white;
      }
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: 3rem;
  }
}

.project-list__focus-area-wrapper {
  display: none;

  .topics-filtered & {
    display: block;
  }
}

// Results
// =======

.project-results {
  @include breakpoint(small only) {
    padding-top: 0.5rem;
  }
}

.project-results__list {
  list-style: none;
  margin-bottom: 1rem;
}

.project-result {
  @extend %divider;
  padding: 1.3rem 0 1rem;

  p {
    font-size: $small-text-font-size;

    @include breakpoint(large up) {
      margin-bottom: 0.25rem;
    }
  }
}

.project-result__label {
  @extend %label;
  display: none;
  margin-bottom: 0.75rem;

  @include breakpoint(medium up) {
    display: block;
  }
}

.project-result__label--topics {
  display: block;
}

.project-result__title {
  font-size: $global-font-size;
}

.project-result__download {
  display: block;
  margin-bottom: 1rem;

  svg {
    width: rem-calc(23px);
    height: rem-calc(26px);
  }
}

.project-result__download-wrapper {
  text-align: right;

  .arrow-link {
    display: none;
  }

  @include breakpoint(medium up) {
    display: flex;
    flex-direction: column;

    .arrow-link {
      display: inline-block;
      margin-top: auto;
      margin-left: auto;
    }
  }
}

.project-result__date-wrapper {
  @include breakpoint(small only) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include breakpoint(medium up) {
    .arrow-link {
      display: none;
    }
  }
}

.project-result__value {
  display: block;
  // margin-bottom: 1.3rem;
  font-size: $small-text-font-size;
}
