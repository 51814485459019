// Topic navigation
// ================

.topic-navigation {
  background-color: $alizarin-crimson;
}

.topic-navigation__list {
  list-style: none;
  padding: 0.75rem 0;

  @include breakpoint(medium up) {
    padding: 0.5rem 0;
  }
}

.topic-navigation__item {
  display: inline-block;
  padding: 0.25rem 1rem 0.25rem 0;
}

.topic-navigation__link {
  color: $white;
  font-size: $small-text-font-size;
  font-weight: bold;

  &.active,
  &:hover {
    color: darken($alizarin-crimson, 20%);
  }
}

// Topic intro
// ===========
$topic-link-length: rem-calc(58);

.topic-intro {
  position: relative;
  overflow: hidden;

  &.page-section {
    padding-bottom: 2.5rem;
  }
}

.topic-link {
  display: none;
  position: absolute;
  top: 50%;
  height: $topic-link-length;
  width: $topic-link-length;

  transform: translateY(-50%);

  &:hover {
    color: $alizarin-crimson;
  }

  &::before {
    position: relative;
    content: "";
    display: inline-block;
    background: $alizarin-crimson no-repeat center / rem-calc(11);
    height: 100%;
    width: $topic-link-length;

    z-index: 10;
  }

  @include breakpoint(large up) {
    display: inline-block;
  }
}

.topic-link--prev {
  left: 0;

  &::before {
    background-image: url("./img/icons/arrow-topic-left.svg");
  }

  .topic-link__label {
    left: $topic-link-length;
    transform: translate(-100%);
    border-left: none;
  }
}

.topic-link--next {
  right: 0;

  &::before {
    order: 2;
    background-image: url("./img/icons/arrow-topic-right.svg");
  }

  .topic-link__label {
    right: $topic-link-length;
    transform: translate(100%);
    border-right: none;
  }
}

.topic-link__label {
  position: absolute;
  top: 0;

  height: 100%;
  width: 15rem;

  padding: 0 1rem;
  border: 2px solid $alizarin-crimson;
  background-color: $white;

  font-weight: bold;
  font-size: $label-font-size;
  text-transform: uppercase;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s;
  will-change: transform;

  .topic-link:hover & {
    transform: translate(0);
  }
}

// Focus area tabs
// ===============

.focus-areas {
  background-color: $deep-cerulean;
  padding-top: 2rem;
}

.focus-areas-content {
  background-color: $pampas;
  padding-top: 2rem;
}

.focus-area__projects {
  background-color: $white;
  padding: 2rem 0;
  margin-top: 2rem;

  @include breakpoint(medium up) {
    padding: 3rem 0 5rem;
  }
}

.focus-areas__title {
  color: $white;
}

.focus-areas__tabwrapper {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;

    height: 100%;
    width: 0.5rem;

    background: no-repeat center / contain;
    opacity: 0;

    transition: $global-transition opacity;

    @include breakpoint(medium up) {
      display: none;
    }
  }

  &::before {
    left: 0;
    background-image: linear-gradient(
      to right,
      transparentize($mine-shaft, 0.5),
      transparent
    );
  }

  &::after {
    right: 0;
    background-image: linear-gradient(
      to right,
      transparent,
      transparentize($mine-shaft, 0.5)
    );
  }

  &.scroll-right {
    &::after {
      opacity: 1;
    }
  }

  &.scroll-left {
    &::before {
      opacity: 1;
    }
  }
}

.focus-areas__tablist {
  list-style: none;

  display: flex;
  flex-wrap: nowrap;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: -1px; // iOS Safari hairline workaround
}

.focus-areas__tab {
  @extend %hyphenate;

  display: flex;
  flex: 1 0 auto;

  @include breakpoint(medium up) {
    flex: 1;
  }
}

.focus-areas__link {
  width: 100%;
  padding: 0.5rem 1rem;

  color: $white;
  font-size: $small-text-font-size;
  font-weight: bold;

  transition:
    color $global-transition,
    background-color $global-transition;

  &:hover {
    color: $white;
    background-color: transparentize($pampas, 0.75);
  }

  &.active {
    background-color: $pampas;
    color: $mine-shaft;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.focus-area__tab-content {
  display: none;

  &.active {
    animation: reveal ease-in-out 0.4s;
    display: block;
  }
}

.focus-area__description {
  @extend %divider;
  padding-bottom: 2.3rem;
  margin-bottom: 2rem;

  .button {
    margin-bottom: 0;
    width: 100%;

    @include breakpoint(medium up) {
      width: auto;
    }
  }
}

// Reference project
// =================

.reference-project__label {
  @extend %label;
}

.reference-project__title {
  @extend %h2-font-size;
}

.reference-project__contact-persons {
  padding-top: 2rem;

  .button {
    width: 100%;
    margin-bottom: 0;

    @include breakpoint(medium up) {
      width: auto;
    }
  }
}

// Projects
// ========

.topic-projects {
  padding: 2rem 0rem;

  .button {
    width: 100%;
    margin-bottom: 0;

    @include breakpoint(medium up) {
      width: auto;
      margin-bottom: 1rem;
    }
  }

  @include breakpoint(medium up) {
    padding: 4rem 0rem;
  }
}
