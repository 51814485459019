.button {
  display: inline-block;

  font-weight: bold;
  font-size: $label-font-size;
  color: $primary-color;

  text-align: center;
  text-transform: uppercase;

  border: 3px $primary-color solid;
  padding: 0.9em 2em 0.9em;
  margin-bottom: 1.5rem;

  transition: $global-transition;
  transition-properties: color, background-color;

  &:hover,
  &:focus {
    color: $white;
    background-color: $primary-color;
  }

  @include breakpoint(medium up) {
    font-size: $small-text-font-size;
  }
}

.button--inverted {
  color: $white;
  border-color: $white;

  &:hover,
  &:focus {
    color: $blue-lagoon;
    background-color: $white;
  }
}

.button--blue {
  color: $white;
  border-color: $blue-lagoon;
  background-color: $blue-lagoon;

  &:hover,
  &:focus {
    color: $blue-lagoon;
    background-color: $white;
  }
}
