$gradient-settings: (
  (
    width: 62%,
    color: $alizarin-crimson,
  ),
  (
    width: 21%,
    color: $blue-lagoon,
  ),
  (
    width: 12%,
    color: $deep-cerulean,
  ),
  (
    width: 5%,
    color: $ziggurat,
  )
);

@keyframes blink {
  from {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Chrome has a problem with mutliple hard color stops in a linear gradient. That's why it's now done the ugly way :( */
.gradient {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  .tile {
    flex: 1 0 auto;
  }

  @for $i from 1 through length($gradient-settings) {
    .tile:nth-child(#{$i}) {
      background-color: map-get(nth($gradient-settings, $i), "color");
      width: map-get(nth($gradient-settings, $i), "width");
    }
  }
}

.startscreen {
  display: block;
  position: relative;
  height: 100vh;
  padding-top: 20vh;
}

.startscreen__title {
  font-size: 1.6rem;
  color: $white;
  max-width: 62%;

  animation: fade-in 0.85s ease;

  @include breakpoint(medium up) {
    font-size: 2.9rem;
  }

  @include breakpoint(large up) {
    max-width: 80%;
  }
}

.startscreen__scroll {
  position: absolute;
  left: 50%;
  bottom: 15vh;
  transform: translate(-50%);

  color: $white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: $label-font-size;

  display: flex;
  flex-flow: wrap column;
  align-items: center;

  animation: blink 5s infinite linear;

  img {
    margin-bottom: 0.5rem;
    width: 1rem;

    @include breakpoint(medium up) {
      width: rem-calc(25px);
    }
  }

  @include breakpoint(medium up) {
    bottom: 10vh;
  }

  &:hover {
    color: $white;
  }
}

// Top Bar
// =======

.top-bar {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: $header-height;
  padding: $header-color-bar-height 0 0 0;

  background-color: $white;

  transition: $global-transition;
  transition-property: box-shadow, height;

  display: flex;
  align-items: center;

  .grid-container {
    width: 100%;
  }

  .gradient {
    height: $header-color-bar-height;
  }

  .expanded-nav &,
  .scrolled & {
    box-shadow: 0 2px 4px $cloudy;
  }

  .scrolled.no-sticky-header & {
    box-shadow: none;
    transition: none;
  }

  &[data-sticky-header] {
    position: relative;

    .sticky & {
      position: fixed;
    }
  }

  @include breakpoint($header-bp) {
    height: $header-height-medium;
    padding-top: $header-color-bar-height-medium;
    background-size: 100% $header-color-bar-height-medium;

    .scrolled & {
      height: $header-height-scrolled;
    }

    .gradient {
      height: $header-color-bar-height-medium;
    }
  }

  @include breakpoint(large up) {
    height: $header-height-large;
    padding-top: $header-color-bar-height-large;
    background-size: 100% $header-color-bar-height-large;
  }
}

.top-bar__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  @include breakpoint($header-bp) {
    align-items: stretch;
  }
}

.logo {
  width: 10rem;

  @include breakpoint($header-bp) {
    width: 15rem;
  }

  @include breakpoint(large up) {
    // width: rem-calc(302);
  }
}

// Navigation
// ==========

.navigation {
  display: flex;
  width: 100%;
  padding-top: 1rem;

  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  .button {
    font-size: $label-font-size;
  }

  @media #{$header-bp-only} {
    position: absolute;
    top: 100%;
    left: 0;

    visibility: hidden;
    opacity: 0;

    background-color: $white;
    padding-left: $column-gutter-small * 0.5;
    padding-right: $column-gutter-small * 0.5;

    transition: $navigation-transition;
    transition-property: opacity, visibility;
    will-change: opacity, visibility;

    .expanded-nav & {
      visibility: visible;
      opacity: 1;
    }
  }

  @include breakpoint($header-bp) {
    position: static;
    width: auto;
    padding: 0;

    align-items: flex-end;
  }
}

.navigation__list {
  list-style: none;
  width: 100%;
  padding-top: 1rem;

  li {
    margin-bottom: 1rem;
  }

  @include breakpoint($header-bp) {
    width: auto;
    padding-top: 0;
    margin-bottom: 0.2rem;
    text-align: right;

    li {
      display: inline-block;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
}

.navigation__item {
  text-transform: uppercase;
  font-weight: bold;
  font-size: $small-text-font-size;
  color: $blue-lagoon;

  &:hover {
    color: $alizarin-crimson;
  }

  &.active {
    color: $alizarin-crimson;

    &:hover {
      color: darken($alizarin-crimson, 5%);
    }
  }
}

// Language Navigation
// ===================

.lang-nav {
  list-style: none;
}

.lang-nav__item {
  display: inline-block;
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.lang-nav__link {
  @extend %label;
  color: $blue-lagoon;

  &:hover,
  &.active {
    color: $alizarin-crimson;
  }
}

// Navigation Toggle
// =================

.navigation-toggle {
  order: 3; // IE11 Workaround

  .bars {
    width: 2rem;
    height: 2rem;
  }

  &:focus,
  &:active {
    .bars {
      position: relative;
    }
  }

  @include breakpoint($header-bp) {
    display: none;
  }
}
