.f3cc {
  --f3cc-background: #f8f7f4;
  --f3cc-foreground: #000000;

  --f3cc-accept-background: #cbcbcb;
  --f3cc-accept-foreground: #000;

  --f3cc-button-background: #068aa6;
  --f3cc-button-foreground: #fff;
}
