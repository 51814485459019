.eyecatcher {
  @extend %inherit-heading-colors;
  color: $white;
  background-color: $alizarin-crimson;

  width: 100%;
  padding: 1.5rem 1.5rem 0.5rem;

  @include breakpoint(medium up) {
    padding: 2rem 2rem 1rem;
  }

  a {
    color: inherit;
  }
}
