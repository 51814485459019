@font-face {
  font-family: "Arial";
  src: local("Arial Italic"), local("Arial-ItalicMT"),
    url("./Arial-ItalicMT.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Arial";
  src: local("Arial"), local("ArialMT"), url("./ArialMT.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: local("Arial Bold Italic"), local("Arial-BoldItalicMT"),
    url("./Arial-BoldItalicMT.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Arial Narrow";
  src: local("Arial Narrow Bold Italic"), local("ArialNarrow-BoldItalic"),
    url("./ArialNarrow-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Arial Narrow";
  src: local("Arial Narrow Bold"), local("ArialNarrow-Bold"),
    url("./ArialNarrow-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Arial Narrow";
  src: local("Arial Narrow Italic"), local("ArialNarrow-Italic"),
    url("./ArialNarrow-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Arial Narrow";
  src: local("Arial Narrow"), local("ArialNarrow"), url("./ArialNarrow.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: local("Arial Bold"), local("Arial-BoldMT"), url("./Arial-BoldMT.woff2")
    format("woff2");
  font-weight: bold;
  font-style: normal;
}
