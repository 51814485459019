// About Us Header
// ===============

.about-header {
  @extend %inherit-heading-colors;
  color: $white;
}

.about-header--eyecatcher {
  padding-bottom: 0;
}

.about-header__eyecatcher {
  display: flex;
  justify-content: flex-end;
}

.about-header__eyecatcher-link {
  display: inline-block;
  background-color: $alizarin-crimson;

  color: $white;
  font-size: $small-text-font-size;
  font-weight: bold;
  text-transform: uppercase;

  padding: 1rem 1.5rem;

  transition: opacity $global-transition;

  &:hover {
    color: $white;
    opacity: 0.5;
  }
}

// Team
// ====

.team {
  list-style: none;
  margin-bottom: 1.5rem;
}

.team__title {
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    margin-bottom: 2.5rem;
  }
}

.team__item {
  @extend %divider;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.team__image {
  margin-bottom: 1rem;
}

.team__member-title {
  @extend %h3-font-size;
  margin-bottom: 0;
}

.team__job-function {
  @extend %hyphenate;
  display: block;
  margin-bottom: 1rem;
  font-size: $small-text-font-size;
}

.team__job-description {
  @extend %label;
  @extend %hyphenate;
}

.team__person {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team__footer__icon-container {
  display: flex;
  > * {
    margin-right: 1rem;
  }
}

.team__content {
  // thanks IE
  // https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
  min-height: 1px;
}

.team__email {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
