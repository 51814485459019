@use "sass:math";

.four-o-four {
  color: $white;
  overflow: hidden;

  .page-section {
    padding-bottom: 0;
  }
}

.richtext {
  .four-o-four__title {
    color: inherit;
    margin-bottom: 1.25rem;
    font-size: 2.5rem;

    @include breakpoint(medium up) {
      font-size: 6rem;
      margin-bottom: 2rem;
    }

    @include breakpoint(large up) {
      font-size: 9rem;
    }
  }

  .four-o-four__subtitle {
    margin-bottom: 1.25rem;
    font-size: 1.125rem;

    @include breakpoint(medium up) {
      font-size: 1.75rem;
      margin-bottom: 2rem;
    }

    @include breakpoint(large up) {
      font-size: 2.25rem;
    }
  }

  .four-o-four__error {
    margin-bottom: 1.25rem;

    @include breakpoint(medium up) {
      margin-bottom: 2.25rem;
    }
  }
}

$wire-aspect-ratio: math.div(263, 1440);
$wire-zoom-factor-small: 2.5;
$wire-zoom-factor-medium: 1.25;

@function get-wire-height($aspect-ratio, $zoom-factor: 1) {
  @return ($aspect-ratio * 100%) * $zoom-factor;
}

.four-o-four__wire {
  position: relative;
  margin: 2rem 0 0;

  height: 0;
  padding-bottom: get-wire-height($wire-aspect-ratio, $wire-zoom-factor-small);

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    transform: scale($wire-zoom-factor-small);

    @include breakpoint(medium up) {
      transform: scale($wire-zoom-factor-medium);
    }

    @include breakpoint(large up) {
      transform: none;
    }
  }

  @include breakpoint(medium up) {
    margin: 4rem 0;
    padding-bottom: get-wire-height(
      $wire-aspect-ratio,
      $wire-zoom-factor-medium
    );
  }

  @include breakpoint(large up) {
    margin: 6rem 0 4rem;
    padding-bottom: get-wire-height($wire-aspect-ratio);
  }
}
