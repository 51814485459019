.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  list-style: none;
  padding-top: 1rem;

  font-weight: bold;
  font-size: $label-font-size;

  @include breakpoint(medium up) {
    justify-content: flex-end;
  }
}

.pagination__item {
  display: inline-block;
  margin: 0 0.5rem;
}

.pagination__arrow {
  margin: 0 0.5rem;

  display: flex;
  align-items: center;
}

.pagination__arrow--prev {
  svg {
    transform: rotate(180deg);
  }
}

.pagination__link {
  .arrow {
    fill: $alizarin-crimson;
  }

  &:hover {
    .arrow {
      fill: lighten($primary-color, 20%);
    }
  }

  .pagination__arrow & {
    display: flex;
    align-items: center;
  }
}
