.icon.linkedin {
  color: transparent;
  overflow: hidden;
  display: block;
  background: url("img/icons/linkedin.svg") no-repeat center / contain;
  width: rem-calc(32);
  height: rem-calc(32);
  &.red.small {
    background: url("img/icons/linkedin-red.svg") no-repeat center / contain;
    width: rem-calc(24);
    height: rem-calc(24);
  }
}
