// Blacks
$black: #000000;
$mine-shaft: #282828;

// Red
$alizarin-crimson: #e72636;

// Blues
$blue-lagoon: #006c8b;
$deep-cerulean: #068aa6;
$ziggurat: #bcd6e2;
$pelorous: #36a4b3;

// Grays
$pampas: #f8f7f4;
$dove-gray: #666666;
$cloudy: #acaba9;

$primary-color: $alizarin-crimson;
