.blog-archive__list {
  list-style: none;
  margin-bottom: 1rem;
}

.blog-archive__title {
  margin-bottom: 3rem;
}

.blog-archive__label {
  @extend %label;
}

.blog-archive__article-title {
  @extend %h3-font-size;
  margin-bottom: 1rem;
}

.blog-archive__article {
  @extend %divider;
  padding: 1.4rem 0 1rem;
}
