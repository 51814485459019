// Download list
// =============

.download-list {
  list-style: none;
  margin-bottom: 2.5rem;

  @include breakpoint(medium up) {
    margin-bottom: 1rem;
  }
}

.download-list__item {
  font-size: $label-font-size;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.download-list__link {
  display: inline-block;
  padding: 0.6rem 0 0 2.5rem;
  position: relative;
}

.download-list__link--icon {
  .download,
  .link {
    position: absolute;
    left: 0;
    top: 0;
  }

  .download {
    width: rem-calc(22px);
  }

  .link {
    top: rem-calc(6px);
    width: rem-calc(22px);
  }

  &:hover {
    .download g,
    .link path {
      fill: lighten($primary-color, 20%);
    }
  }
}
