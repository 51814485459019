// custom gutter for the topic teaser. it's only used for this element.
$topic-teaser-gutter: $column-gutter-medium * 0.5;

.topic-teaser {
  @include breakpoint(medium up) {
    margin-bottom: $column-gutter-medium;

    display: flex;
    flex-flow: row wrap;

    // break out of the foundation cell with the custom gutter
    margin-left: -($column-gutter-medium * 0.5 - $topic-teaser-gutter * 0.5);
    margin-right: -($column-gutter-medium * 0.5 - $topic-teaser-gutter * 0.5);
  }
}

.topic-teaser__item {
  margin-bottom: $column-gutter-small;

  @include breakpoint(medium up) {
    width: calc(33.33333% - #{$topic-teaser-gutter});
    margin: $topic-teaser-gutter * 0.5;
  }
}

.topic-teaser__link {
  position: relative;
  display: flex;
  align-items: flex-end;
  color: $white;
  font-weight: bold;
  padding: 1rem;
  background: $blue-lagoon center / cover no-repeat;

  &:hover {
    color: $white;

    .topic-teaser__image {
      &:after {
        opacity: 0;
      }
      opacity: 1;
    }
  }

  @include breakpoint(small only) {
    // override style attribute :(
    background-image: none !important;
  }

  @include breakpoint(medium up) {
    padding: 60% 1rem 1rem;
    height: 0;
  }
}

.topic-teaser__title {
  position: relative;
}

.topic-teaser__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $blue-lagoon center / cover no-repeat;
    mix-blend-mode: multiply;
    transition: opacity $global-transition;
  }

  &.topic-teaser__image--no-image {
    opacity: 0;
    &:after {
      background: none;
      mix-blend-mode: normal;
    }
  }

  background: $alizarin-crimson center / cover no-repeat;

  transition: opacity $global-transition;

  @include breakpoint(small only) {
    // override style attribute :(
    background-image: none !important;
  }
}
