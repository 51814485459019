form {
  input,
  select {
    width: 100%;

    margin-bottom: 1rem;
    padding: 0.7em 1.27em;

    border: none;
    font-size: $small-text-font-size;
  }
}
