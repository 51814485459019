.person {
  p,
  a {
    font-size: $body-font-size-small;
    margin-bottom: 1rem;
  }
}

.person__title {
  @extend %h2-font-size;
}

.person__image {
  display: block;
  margin-bottom: 1rem;
}

.person__contact-link {
  display: inline-block;
  margin-bottom: 1rem;
}

.person__back-link {
  display: flex;
  align-items: center;

  svg {
    transform: rotate(180deg);
    margin-right: 1rem;
  }

  .arrow {
    fill: $alizarin-crimson;
  }

  &:hover {
    .arrow {
      fill: lighten($primary-color, 20%);
    }
  }
}
