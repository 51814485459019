.blog-teaser {
  .button {
    margin-bottom: 0rem;
    @include breakpoint(medium up) {
      margin-bottom: 0;
    }
  }
}

.blog-teaser__label {
  @extend %label;
  margin-bottom: 1rem;
}

.blog-teaser__title {
  @extend %h2-font-size;
}

.blog-teaser__image {
  margin-bottom: 1.2rem;

  @incude breakpoint(medium up) {
    margin-bottom: 0;
  }
}
