.project__title {
  @extend %h2-font-size;
  margin-top: 1rem;
}

.project__section {
  @extend %divider;
  margin-bottom: 1.3rem;
  padding-bottom: 0.4rem;

  p {
    font-size: $small-text-font-size;
  }
}

.project__section--main {
  padding-top: 0rem;

  p {
    font-size: $body-font-size;
  }
}

.project__section-title {
  @extend %label;
  margin-bottom: 1rem;
}

.project__meta-term {
  @extend %label;
  margin-bottom: 0.5rem;
}

.project__meta-definition {
  margin-bottom: 1rem;
  font-size: $small-text-font-size;
}

// Project images
// ==============

.project__image-wrapper {
  margin-bottom: 1.5rem;
}

.project__image {
  display: block;
}

.project__image-caption {
  font-size: $small-text-font-size;
  margin-top: 0.5rem;
}

// Sharing
// =======

.at-share-btn {
  margin-right: 0.75rem;

  &:last-child {
    margin-right: 0;
  }
}

.at-share-btn-elements {
  margin-bottom: 1rem;
}
