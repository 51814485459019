.contact-person__image {
  display: block;
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    margin-bottom: 0;
  }
}

.contact-person__label {
  @extend %label;
  margin-bottom: 0.75rem;
}

.contact-person__title {
  margin-bottom: 0.75rem;
}

.contact-person__description {
  @extend %hyphenate;
  font-size: $small-text-font-size;
}
